import { observer } from "mobx-react-lite";
import Dialog from "./dialog";
import { isFunc } from "@utils";
import { useCategoryPost as usePost, useCategoryUpdate as useUpdate } from "@api";

const Default = observer((props) => {
  const { useContext, reload } = props;
  const { dialog = {} } = useContext ? useContext() : {};

  const [callbackPost] = usePost();
  const [callbackUpdate] = useUpdate();

  const handleOnClose = () => {
    dialog.setIsShowEdit(false);
  };

  const handleOnSave = async (data) => {
    const category = data.category;

    category?.id ? callbackUpdate(category) : callbackPost(category);
    handleOnClose();
    isFunc(reload);
  };

  if (dialog.isShowEdit) {
    return <Dialog id={dialog?.data?.select} onClose={handleOnClose} onSave={handleOnSave} />;
  }
  return null;
});

export default Default;

export const compressImage = (file, maxSize = 1 * 512 * 512) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (file.size < 400000) {
      // Если исходник картинки весит меньше 400 Кб, то не сжимаем её
      resolve(file);
    }

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Сохраняем пропорции изображения
        // const MAX_WIDTH = img.width
        // const MAX_HEIGHT = img.height
        let width = img.width;
        let height = img.height;

        // Ограничиваем размеры, если нужно
        if (width > 1000 || height > 1000) {
          if (width > height) {
            height *= 1000 / width;
            width = 1000;
          } else {
            width *= 1000 / height;
            height = 1000;
          }
        }

        canvas.width = width;
        canvas.height = height;

        // Рисуем изображение на canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Пробуем сжать изображение
        canvas.toBlob(
          (blob) => {
            if (blob && blob.size <= maxSize) {
              resolve(blob); // Возвращаем результат, если размер подходит
            } else {
              // Повторное сжатие с уменьшением качества
              let quality = 0.9; // Начальное качество
              const compress = () => {
                canvas.toBlob(
                  (compressedBlob) => {
                    if (compressedBlob && compressedBlob.size <= maxSize) {
                      resolve(compressedBlob);
                    } else if (quality > 0.1) {
                      quality -= 0.1;
                      compress();
                    } else {
                      reject(Error("Не удалось сжать изображение до нужного размера."));
                    }
                  },
                  "image/jpeg",
                  quality
                );
              };
              compress();
            }
          },
          "image/jpeg",
          1.0
        );
      };

      img.onerror = () => {
        reject(Error("Не удалось загрузить изображение."));
      };
    };

    reader.onerror = () => {
      reject(Error("Ошибка чтения файла."));
    };

    reader.readAsDataURL(file);
  });
};

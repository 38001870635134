import { memo } from "react";
import { Text, IconButton, Box, Icon } from "@components";
import { areEqualObject, getDateFromString as getDate } from "@utils";
import { useNavigate } from "react-router-dom";

const Default = memo((props) => {
  const { id, status, updatedAt } = props;
  const isPaid = status !== "open" && status !== "pending" && status !== "cancelled";

  const navigate = useNavigate();

  const handleOnShowOrder = (event) => {
    navigate(`/admin/orders/order/${id}`);
    event.stopPropagation();
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 1,
        alignItems: "center",
        padding: (them) => them.spacing(0, 1, 0, 1)
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {status === "open" ? (
          <Text
            sx={{
              flexGrow: 1,
              fontWeight: "700",
              fontSize: "18px",
              color: "red"
            }}
            caption={"У клиента есть брошенная корзина с товарами!"}
          />
        ) : (
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <Text sx={{ flexGrow: 0 }} caption={`Заказ #${id}`} />
            <Text sx={{ flexGrow: 1, marginLeft: "8px", fontWeight: "700" }} caption={`(Статус: ${status})`} />
          </div>
        )}
        {status !== "open" && (
          <Text
            variant="caption"
            display="block"
            gutterBottom
            sx={{ flexGrow: 1 }}
            caption={`Последнее обновление статуса заказа: ${getDate(updatedAt)}`}
          />
        )}
      </Box>
      {status !== "open" && <Icon textIcon={isPaid ? "paid" : "money_off"} color={isPaid ? "success" : "error"} />}
      <IconButton textIcon="shopping_cart_checkout" color="primary" onClick={handleOnShowOrder} />
    </Box>
  );
}, areEqualObject);

export default Default;

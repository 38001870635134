import React from "react";

const Default = (props) => {
  const { children, ...other } = props;

  if (children.length > 1) {
    return children
      .filter((item) => item !== null)
      .map((item, index) => {
        if (item?.type) {
          return <item.type key={index} {...other} {...item.props} />;
        }
        if (item) {
          return React.cloneElement(item, {
            key: index,
            ...other,
            ...item?.props
          });
        }
        return null;
      });
  } else {
    if (children) {
      return React.cloneElement(children, {
        key: 1,
        ...other,
        ...children?.props
      });
    }
  }
};

export default Default;

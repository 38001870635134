import { memo, useState, useCallback, useEffect } from "react";
import { Box, IconButton, Input, Autocomplete } from "@components";
import { useOrdersContext as useContext } from "@context";
import { isFunc, areEqualObject } from "@utils";
import { useOrdersGetAll as useGetById } from "@api";

const Default = memo((props) => {
  const { select, selectCount, setOrderStatus, onClear, onSearch } = props;

  const { dialog } = useContext();
  const [data, setData] = useState({});
  const [callbackGet, loading] = useGetById();

  const orderStatuses = ["open", "pending", "paid", "delivered", "shipped", "cancelled"];

  useEffect(() => {
    if (data?.status) {
      setOrderStatus(data?.status);
    }
    if (data?.search !== null && data?.search !== undefined) {
      isFunc(onSearch, data?.search);
    }
  }, [data]);

  const handleOnDelete = () => {
    dialog.setIsShowDelete(true, {
      select: select.map((item) => item.id),
      onClear,
      onGetText: () => select.map((item) => item.caption).join(",\n")
    });
  };

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        if (param === "status") {
          prev.status = event.target.value ? event.target.value.caption : null;
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  const handleOnClear = useCallback(() => {
    setOrderStatus(null);
  }, []);

  const useGetAll = () => {
    return [
      useCallback((setData) => {
        setData(orderStatuses.map((item, index) => ({ id: index, caption: item })));
      }),
      loading
    ];
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Autocomplete
        name="status"
        caption="Статус заказа"
        useGet={useGetAll}
        data={data}
        onChange={handleChange}
        onClear={handleOnClear}
      />
      <Input
        sx={{ marginLeft: 1 }}
        name="search"
        caption="Поиск по названию"
        data={data}
        onChange={handleChange}
        changeOnEnter
      />
      <Box sx={{ flexGrow: 1 }} />
      <IconButton textIcon="delete" color="error" onClick={handleOnDelete} disabled={selectCount === 0} />
    </Box>
  );
}, areEqualObject);

export default Default;

import { observer } from "mobx-react-lite";
import Dialog from "./dialog";
import { isFunc } from "@utils";
import { useArticlePost as usePost, useArticleUpdate as useUpdate, useImagesPost } from "@api";

const Default = observer((props) => {
  const { useContext, reload } = props;
  const { dialog = {} } = useContext ? useContext() : {};

  const [callbackPost] = usePost();
  const [callbackUpdate] = useUpdate();

  const handleOnClose = () => {
    dialog.setIsShowEdit(false);
  };

  const handleOnSave = async (data) => {
    let article = data.article;

    const image = data.image;
    if (image) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const imageName = await useImagesPost(image);
      if (imageName) {
        article = { ...article, image: imageName };
      }
    }

    article?.id ? callbackUpdate(article) : callbackPost(article);
    handleOnClose();
    isFunc(reload);
  };

  if (dialog.isShowEdit) {
    return <Dialog id={dialog?.data?.select} onClose={handleOnClose} onSave={handleOnSave} />;
  }
  return null;
});

export default Default;

import { Box, Input, Container, Autocomplete } from "@components";
import { isFunc, checkInput } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { DialogContent } from "@mui/material";
import Actions from "./actions";
import { useOrdersGetById as useGetById, useDeliveryAddressGetById } from "@api";

// eslint-disable-next-line react/display-name
export default (props) => {
  const { onClose, onSave, id } = props;

  const [data, setData] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState({});

  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = useGetById();
  const [callbackGetDeliveryInfo, loadingDeliveryInfo] = useDeliveryAddressGetById();

  const orderStatus = ["pending", "paid", "delivered", "shipped"];
  const [changeOrderStatusEnabled, setChangeOrderStatusEnabled] = useState(false);

  useEffect(() => {
    if (data.status) {
      setChangeOrderStatusEnabled(orderStatus.includes(data.status));
    }
  }, [data]);

  const getStatuses = () => {
    return [
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback((setData) => {
        setData(orderStatus.map((item, index) => ({ id: index, caption: item })));
      }),
      loading
    ];
  };

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
      callbackGetDeliveryInfo(id, setDeliveryInfo);
    }
  }, [id, callbackGet, callbackGetDeliveryInfo]);

  const validate = useCallback((data) => {
    return checkInput(data, [
      {
        name: "caption",
        maxLength: 200,
        minLength: 3,
        errorMessage: "Заголовок не должен быть больше 30 и меньше 3"
      }
    ]);
  }, []);

  const handleOnSave = useCallback(() => {
    setSave((prev) => !prev);
  }, []);

  useEffect(() => {
    if (save) {
      isFunc(onSave, { ...data, ...deliveryInfo, id });
    }
  }, [onSave, data, save]);

  useEffect(() => {
    setError(validate(data));
  }, [data, validate]);

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        if (param === "status") {
          prev.status = event.target.value.caption;
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  const handleDeliveryInfoChange = useCallback((param) => {
    return (event) => {
      setDeliveryInfo((prev) => {
        prev[param] = event.target.value;
        return { ...prev };
      });
    };
  }, []);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Autocomplete name="status" caption={"Статус: " + data.status} useGet={getStatuses} />
            <Input isMultiline={true} name="description" caption="Комментарий" disabled={!changeOrderStatusEnabled} />
          </Container>
          <Container
            error={error}
            data={deliveryInfo}
            onChange={handleDeliveryInfoChange}
            loading={loadingDeliveryInfo}
          >
            <Input name="country" caption="Страна" />
            <Input name="city" caption="Город" />
            <Input name="address" caption="Адрес" />
            <Input name="postcode" caption="Почтовый индекс" />
            <Input name="name" caption="Имя" />
            <Input name="phone" caption="Телефон" />
            <Input name="email" caption="Email" />
          </Container>
        </Box>
      </DialogContent>
      <Actions positiveButtonCaption="Edit" handleOnSave={handleOnSave} disabled={error.isError} onClose={onClose} />
    </>
  );
};

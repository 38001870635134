/* eslint-disable no-template-curly-in-string */
import { Box, Input, Container, Autocomplete } from "@components";
import { isFunc, checkInput, compressImage } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { DialogContent, TextField } from "@mui/material";
import Actions from "./actions";
import { useArticleGetById as useGetById, useCategoryGetAll as useGetAll, useImagesGetWithStatus } from "@api";

export default (props) => {
  const { onClose, onSave, id } = props;

  const [data, setData] = useState({});
  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = useGetById();
  const [image, setImage] = useState(null);
  const [imagePlaceholder, setImagePlaceholder] = useState(null);
  const [imagePlaceholderStatus, setImagePlaceholderStatus] = useState(404);
  const [isUpdateImage, setIsUpdateImage] = useState(false);
  const [isFinishedLoadImage, setFinishedLoadImage] = useState(false);

  const validate = useCallback((data) => {
    return checkInput(data, [
      {
        name: "caption",
        maxLength: {
          val: 200,
          errorMessage: "Заголовок не должен быть длиннее ${val} символов"
        },
        minLength: {
          val: 3,
          errorMessage: "Заголовок не должен быть короче ${val} символов"
        }
      }
    ]);
  }, []);

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
    }
  }, [id, callbackGet]);

  useEffect(() => {
    if (save) {
      isFunc(onSave, {
        article: { ...data, id },
        image
      });
    }
    if (data.image) {
      loadExistedImage(data.image);
    }
  }, [onSave, data, save]);

  useEffect(() => {
    setError(validate(data));
  }, [data, validate]);

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        if (param === "category") {
          prev.categoryId = event.target.value.id;
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("Файл не выбран.");
      return;
    }

    if (!file.type.startsWith("image/")) {
      console.log("Выбранный файл не является изображением.");
      return;
    }

    try {
      setFinishedLoadImage(false);
      setIsUpdateImage(true);
      const compressedBlob = await compressImage(file);
      const compressedFile = new File([compressedBlob], file.name, { type: file.type });
      setImage(compressedFile);
      setFinishedLoadImage(true);
      console.log(
        "Изображение успешно сжато. Картинка весила: " +
          file.size / 1000 +
          " Кб, сжата до: " +
          compressedBlob.size / 1000 +
          " Кб"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadExistedImage = async (imageName) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { url, status } = await useImagesGetWithStatus(imageName);
    setImagePlaceholderStatus(status);
    if (status === 200) {
      setImagePlaceholder(url);
    }
  };

  const handleOnSave = useCallback(() => {
    setSave((prev) => !prev);
  }, []);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Input name="caption" caption="Заголовок" />
            <Input isMultiline={true} name="description" caption="Описание" />
            {/* TODO: - show image */}
            <div style={{ border: "1px solid gray", borderRadius: "5px", padding: "16px", backgroundColor: "#f0f0f8" }}>
              <p style={{ margin: "-4px 0 8px 0px" }}>
                Изображение товара:{" "}
                {(data?.image ? data?.image : "Не выбрано") +
                  (imagePlaceholderStatus !== 200 && data?.image ? " (Файл изображения не найден)" : "")}
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                {imagePlaceholder ? (
                  <img style={{ width: "100px", height: "100px" }} src={imagePlaceholder} alt="image" />
                ) : (
                  false
                )}
                <TextField
                  style={{
                    width: "100%",
                    marginLeft: imagePlaceholderStatus !== 200 || !imagePlaceholder ? "0px" : "16px"
                  }}
                  type="file"
                  onChange={handleImageChange}
                  inputProps={{ accept: "image/*" }}
                />
              </div>
            </div>
            <Autocomplete name="category" caption="Категория" useGet={useGetAll} />
          </Container>
        </Box>
      </DialogContent>
      <Actions
        handleOnSave={handleOnSave}
        disabled={error.isError || (isFinishedLoadImage === false && isUpdateImage === true)}
        onClose={onClose}
      />
    </>
  );
};

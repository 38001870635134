import { Box, Input, Container } from "@components";
import { isFunc, checkInput } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { DialogContent } from "@mui/material";
import Actions from "./actions";
import { useClientsGetById as useGetById } from "@api";

export default (props) => {
  const { onClose, onSave, id } = props;

  const [data, setData] = useState({});
  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = useGetById();

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
    }
  }, [id, callbackGet]);

  const validate = useCallback((data) => {
    return checkInput(data, [
      {
        name: "caption",
        maxLength: 200,
        minLength: 3,
        errorMessage: "Заголовок не должен быть больше 30 и меньше 3"
      }
    ]);
  }, []);

  const handleOnSave = useCallback(() => {
    setSave((prev) => !prev);
  }, []);

  useEffect(() => {
    if (save) {
      isFunc(onSave, { ...data, id });
    }
  }, [onSave, data, save]);

  useEffect(() => {
    setError(validate(data));
  }, [data, validate]);

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        if (param === "deliveryAddress.address") {
          prev.deliveryAddress.address = event.target.value;
        } else if (param === "deliveryAddress.country") {
          prev.deliveryAddress.country = event.target.value;
        } else if (param === "deliveryAddress.city") {
          prev.deliveryAddress.city = event.target.value;
        } else if (param === "deliveryAddress.postcode") {
          prev.deliveryAddress.postcode = event.target.value;
        } else if (param === "deliveryAddress.state") {
          prev.deliveryAddress.state = event.target.value;
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Input name="caption" caption="First Last Name" disabled={true} />

            <Input name="chatId" caption="Chat id" disabled={false} />

            <Input name="deliveryAddress.country" caption="Country" disabled={false} />
            <Input name="deliveryAddress.city" caption="City" disabled={false} />
            <Input name="deliveryAddress.address" caption="Address" disabled={false} />
            <Input name="deliveryAddress.postcode" caption="Postcode" disabled={false} />

            {/* <Input name="description" caption="Описание" /> */}
          </Container>
        </Box>
      </DialogContent>
      <Actions handleOnSave={handleOnSave} disabled={error.isError} onClose={onClose} />
    </>
  );
};

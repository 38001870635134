const urlBase = "/api/images";

const usePost = async (image) => {
  try {
    const formData = new FormData();
    formData.append("image", image);

    const response = await fetch(urlBase, {
      method: "POST",
      headers: {
        Authorization: "JWT " + localStorage.getItem("token")
      },
      body: formData
    });
    if (!response.ok) {
      throw new Error("Request failed");
    }

    const json = await response.json();
    const fileName = json.fileName;
    return fileName;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const useGet = async (imageName) => {
  try {
    const response = await fetch(`${urlBase}?imageName=${imageName}`, {
      method: "GET"
    });
    if (!response.ok) {
      throw new Error("Request failed");
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const useGetWithStatus = async (imageName) => {
  const response = await fetch(`${urlBase}?imageName=${imageName}`, {
    method: "GET"
  }).catch((error) => console.log(error));

  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  return { url, status: response.status };
};

export { usePost, useGet, useGetWithStatus };

import { useCallback } from "react";
import useParamsApi from "./useParamsAPI";

const urlBase = "/api/cart/updateDeliveryInfo";

const useUpdate = () => {
  const { put, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (data) => {
        put(`?orderId=${data.id}`, data);
      },
      [response, put]
    ),
    loading
  ];
};

export { useUpdate };

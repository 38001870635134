import { getValue } from './utils'

const convert = (args, template) => {
  return template.replace(/\$\{(\w+)\}/g, (m, n) => {
    return args[n] !== undefined && args[n] !== null ? args[n] : m;
  });
};

export default (data = {}, validate = []) => {
  const error = {}

  validate.forEach((item) => {
    const { name, maxLength, minLength, isNotNull, minValue, maxValue, isNumeric, isInteger } = item

    const val = getValue(data, name) ? getValue(data, name) : ''

    // Проверка на обязательность значения
    if (isNotNull && val.length === 0) {
      error[name] = isNotNull.errorMessage
      return
    }

    // Проверка на максимальную и минимальную длину
    if (maxLength && val.trim().length > maxLength.val) {
      error[name] = convert({ val: maxLength.val }, maxLength.errorMessage)
      return
    }
    if (minLength && val.trim().length < minLength.val) {
      error[name] = convert({ val: minLength.val }, minLength.errorMessage)
      return
    }

    // Проверка на минимальное и максимальное значение
    const numericVal = parseFloat(val)
    if (minValue && numericVal < minValue.val) {
      error[name] = convert({ val: minValue.val }, minValue.errorMessage)
      return
    }
    if (maxValue && numericVal > maxValue.val) {
      error[name] = convert({ val: maxValue.val }, maxValue.errorMessage)
      return
    }

    // Проверка на числовое значение (для цены)
    if (isNumeric && !/^\d+(\.\d+)?$/.test(val)) {
      error[name] = isNumeric.errorMessage
      return
    }

    // Проверка на целое число (для количества)
    if (isInteger && !/^\d+$/.test(val)) {
      error[name] = isInteger.errorMessage
    }
  })

  error.isError = Object.keys(error).length > 0

  return error
}

/* eslint-disable no-template-curly-in-string */
import { Box, Input, Container, Autocomplete } from "@components";
import { isFunc, checkInput } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { DialogContent } from "@mui/material";
import Actions from "./actions";
import { useGoodCompositionGetById as useGetById, useGoodGetAll as useGetAll } from "@api";

export default (props) => {
  const { onClose, onSave, id } = props;
  const [data, setData] = useState({});
  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = useGetById();

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
    }
  }, [id, callbackGet]);

  const validate = useCallback((data) => {
    return checkInput(data, [
      {
        name: "count",
        minValue: {
          val: 0,
          errorMessage: "Количество должно быть больше ${val}"
        },
        maxValue: {
          val: id ? data.maxCount : data.goodId ? data.goodId.warehouse.availability : 999999,
          errorMessage: "Количество должно быть меньше ${val}"
        }
      },
      {
        name: "goodId",
        isNull: true,
        errorMessage: "Должен быть выбранный"
      }
    ]);
  }, []);

  const handleOnSave = useCallback(() => {
    if (data?.goodId?.warehouse?.availability < data.count) {
      setError({ count: "Количество должно быть меньше ${val}" });
      return;
    }
    setSave((prev) => !prev);
  }, []);

  useEffect(() => {
    if (save) {
      isFunc(onSave, { ...data, id });
    }
  }, [onSave, data, save]);

  useEffect(() => {
    setError(validate(data));
  }, [data, validate]);

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        prev[param] = event.target.value;
        return { ...prev };
      });
    };
  }, []);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Autocomplete name="goodId" caption="Товар" useGet={useGetAll} loadOnInput />
            <Input
              name="count"
              caption={
                "Количество" +
                (data && data?.goodId?.warehouse
                  ? " (Можно выбрать максимум " +
                    (id ? data?.maxCount : data?.goodId?.warehouse?.availability) +
                    " ед.)"
                  : "")
              }
            />
          </Container>
        </Box>
      </DialogContent>
      <Actions
        handleOnSave={handleOnSave}
        disabled={error.isError || data.count > (data && data?.goodId?.warehouse ? data?.maxCount : 999999)}
        onClose={onClose}
      />
    </>
  );
};

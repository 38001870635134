import { useUserContext as useContext } from "@context";
import { observer } from "mobx-react-lite";
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from "@mui/material";
import login from "../../../res/images/123.jpg";
import { Input, Container, Box } from "@components";
import { useState, useCallback, useEffect } from "react";
import { useAuth } from "@api";
import { hashString } from "react-hash-string";

export default observer((props) => {
  const dataUser = useContext();

  const [data, setData] = useState({});
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [useCallbackAuth, loading] = useAuth();

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        prev[param] = event.target.value;
        setError(false);
        return { ...prev };
      });
    };
  }, []);

  const handleOnLogin = (event) => {
    if (data.login.length !== 0 && data.password.length !== 0) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallbackAuth({ login: data.login, password: hashString(data.password) }, setUser, setError);
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (user && user.isAuth) {
      localStorage.setItem("token", user.accessToken);
      dataUser.data.setIsUser(true);
    }
  }, [user]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <Card sx={{ width: 450, border: "1px solid #e0e0e0" }}>
        <CardContent>
          <CardMedia
            style={{ width: "66px", height: "66px", margin: "0 auto 24px" }}
            component="img"
            image={login}
            alt="green iguana"
          />
          <Typography gutterBottom variant="h5" component="div">
            Войти в систему
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1
            }}
          >
            <Container data={data} onChange={handleChange} disabled={loading}>
              <Input
                style={{ marginTop: "12px" }}
                name="login"
                caption="Логин"
                error={error === true ? { login: "Пользователь не найден, проверьте логин" } : {}}
              />
              <Input
                style={{ marginTop: "16px" }}
                name="password"
                caption="Пароль"
                type="password"
                error={error === true ? { password: "Пользователь не найден, проверьте пароль" } : {}}
              />
            </Container>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button onClick={handleOnLogin} disabled={loading}>
            Войти
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
});

import { useState, useCallback } from "react";
import { useFetch } from "use-http";
import useParamsApi from "./useParamsAPI";
import { isFunc } from "@utils";

const urlBase = "/api/clients";
const urlDelivery = "/api/delivery-address";

const useGetAll = () => {
  const { get, loading, response, abort } = useParamsApi(urlBase);

  return [
    useCallback(
      (setData) => {
        get("").then((data) => {
          setData(data?.rows);
        });
      },
      [response, get]
    ),
    loading,
    abort
  ];
};

const useGet = (countPerPage = 0, currentPage) => {
  const [page, setPage] = useState(currentPage || 1);
  const [search, setSearch] = useState(null);
  const [update, setUpdate] = useState(false);

  const { loading, data: { count: countPage = 0, rows = [] } = {} } = useFetch(
    `${urlBase}?limit=${countPerPage}&offset=${(page - 1) * (countPerPage || 0)}${search ? `&search=${search}` : ""}`,
    {
      data: []
    },
    [page, search, update]
  );
  const usePage = useCallback(
    (page) => {
      setPage(page || 1);
    },
    [countPerPage]
  );

  const useSearch = useCallback((value) => {
    setSearch((prev) => {
      if (prev !== value) {
        setPage(1);
      }
      return value === "" ? null : value;
    });
  }, []);

  const reload = () => {
    setUpdate((prev) => !prev);
  };

  return {
    loading,
    usePage,
    page,
    reload,
    useSearch,
    countPage: Math.ceil(countPage / (countPerPage || 0)),
    items: rows.filter((item) => item.description !== "Admin account")
  };
};

const useGetManyById = (props = {}) => {
  const { get, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (id, setData) => {
        get(`?id=${id}`).then((data) => {
          if (response.ok) {
            const item = data.rows[0];
            setData(data.rows?.length > 0 ? { ...item, caption: `${item.first ?? ""} ${item.last ?? ""}` } : {});
          }
        });
      },
      [response, get]
    ),
    loading
  ];
};

const useGetOneById = (props = {}) => {
  const { get, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (id, setData) => {
        get(`specific?id=${id}`).then((data) => {
          if (response.ok) {
            const filteredOrders = data.orders
              .sort((a, b) => {
                // Высший приоритет для статуса "open" - 
                // (не ордер, а корзина клиента будет показана самой первой в списке)
                if (a.status === "open" && b.status !== "open") return -1;
                if (a.status !== "open" && b.status === "open") return 1;

                // Если статусы одинаковы, сортируем по updatedAt
                return new Date(b.updatedAt) - new Date(a.updatedAt); // Убывание
              })
              .filter((order) => order.totalGoodsCount !== null);

            setData(data ? { ...data, orders: filteredOrders } : {});
          }
        });
      },
      [response, get]
    ),
    loading
  ];
};

const usePost = () => {
  const { post, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (data) => {
        post("", data);
      },
      [response, post]
    ),
    loading
  ];
};

const useUpdate = () => {
  const [updateGeneralInfo, generalLoading] = useUpdateClientGeneralInfo();
  const [updateDeliveryInfo, deliveryLoading] = useUpdateClientDeliveryInfo();

  const updateClientInfo = useCallback(
    (data) => {
      // Обновляем основную информацию
      updateGeneralInfo(data);

      // Проверяем, если данные адреса присутствуют, обновляем адрес
      if (data.deliveryAddress) {
        updateDeliveryInfo({ id: data.id, deliveryAddress: data.deliveryAddress });
      }
    },
    [updateGeneralInfo, updateDeliveryInfo]
  );

  return [updateClientInfo, generalLoading || deliveryLoading];
};

const useUpdateClientGeneralInfo = () => {
  const { put, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (data) => {
        put("", data);
      },
      [response, put]
    ),
    loading
  ];
};

const useUpdateClientDeliveryInfo = () => {
  const { put, loading, response } = useParamsApi(urlDelivery);
  return [
    useCallback(
      (data) => {
        put(`?clientId=${data.id}`, data.deliveryAddress); // Передаем `id` адреса в URL для правильного обновления
      },
      [response, put]
    ),
    loading
  ];
};

const useDelete = (props = {}) => {
  const { reload } = props;
  const { del, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (value) => {
        del("", value).then((data) => {
          isFunc(reload);
        });
      },
      [response, del, reload]
    ),
    loading
  ];
};

export { useGet, useGetAll, useGetOneById, useGetManyById, usePost, useUpdate, useDelete };

import Goods from "./goods";
import Articles from "./articles";
import Categories from "./categories";
import Clients from "./clients";
import Orders from "./orders";
import Order from "./order";
import User from "./user";
import Users from "./users";

export { Goods, Articles, Categories, Clients, Orders, Order, User, Users };

import { memo, useState, useCallback, useEffect } from "react";
import { Box, IconButton, Input, Text } from "@components";
import { useOrderContext as useContext } from "@context";
import { isFunc, areEqualObject } from "@utils";

const Default = memo((props) => {
  const { select, selectCount, onClear, setArticleId, onSearch, order } = props;

  const { dialog } = useContext();
  const [data, setData] = useState({});

  const [totalOrderPriceUsdt, setTotalOrderPriceUsdt] = useState(0);
  const [totalOrderPriceTon, setTotalOrderPriceTon] = useState(0);
  const [totalOrderPriceRub, setTotalOrderPriceRub] = useState(0);

  useEffect(() => {
    if (data?.articleId?.id) {
      setArticleId(data?.articleId?.id);
    }
    if (data?.search !== null && data?.search !== undefined) {
      isFunc(onSearch, data?.search);
    }
  }, [data]);

  useEffect(() => {
    if (order.compositionOrders !== null) {
      setTotalOrderPriceUsdt(
        order?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostUsdt * current.count, 0)
      );
      setTotalOrderPriceTon(
        order?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostTon * current.count, 0)
      );
      setTotalOrderPriceRub(
        order?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostRub * current.count, 0)
      );
    }
  }, [order]);

  const handleOnDelete = () => {
    dialog.setIsShowDelete(true, {
      select: select.map((item) => item.id),
      onClear,
      onGetText: () => select.map((item) => item.caption).join(",\n")
    });
  };

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        prev[param] = event.target.value;
        return { ...prev };
      });
    };
  }, []);

  const handleOnCreate = () => {
    dialog.setIsShowEdit(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "16px" }}>
        <Input
          sx={{ marginLeft: 1 }}
          name="search"
          caption="Поиск по названию"
          data={data}
          onChange={handleChange}
          changeOnEnter
        />
        <Box sx={{ flexGrow: 1 }} />
        <IconButton textIcon="delete" color="error" onClick={handleOnDelete} disabled={selectCount === 0} />
        <IconButton textIcon="add" onClick={handleOnCreate} />
      </Box>
      {totalOrderPriceRub !== undefined && totalOrderPriceTon !== undefined && totalOrderPriceUsdt !== undefined && (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Text
            sx={{ marginLeft: 1 }}
            caption={`Общая сумма заказа: 
        $${totalOrderPriceUsdt.toFixed(2)} ; ${totalOrderPriceTon.toFixed(2)} TON ; ${totalOrderPriceRub.toFixed(2)} ₽`}
          />
        </Box>
      )}
    </div>
  );
}, areEqualObject);

export default Default;

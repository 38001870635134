import { observer } from "mobx-react-lite";
import Dialog from "./dialog";
import { isFunc } from "@utils";
import { useOrdersPost as usePost, useOrdersUpdate as useUpdate, useDeliveryInfoUpdate } from "@api";

const Default = observer((props) => {
  const { useContext, reload } = props;
  const { dialog = {} } = useContext ? useContext() : {};

  const [callbackPost] = usePost();
  const [callbackUpdate] = useUpdate();
  const [callbackDeliveryUpdate] = useDeliveryInfoUpdate();

  if (dialog.isShowEdit) {
    const handleOnClose = () => {
      dialog.setIsShowEdit(false);
    };

    const handleOnSave = (data, deliveryInfo) => {
      const generalInfo = {
        id: data.id,
        status: data.status,
        description: data.description
      };
      data?.id ? callbackUpdate(generalInfo) : callbackPost(generalInfo);
      callbackDeliveryUpdate(data);
      handleOnClose();
      isFunc(reload);
    };

    return <Dialog id={dialog?.data?.select} onClose={handleOnClose} onSave={handleOnSave} />;
  }
  return null;
});

export default Default;

import { memo, useEffect, useState } from "react";
import { Text, Box } from "@components";
import { areEqualObject, getDateFromString as getDate } from "@utils";
import { useOrderGetById as useGet } from "@api";

const Default = memo((props) => {
  const { id, expanded } = props;

  const [data, setData] = useState({});
  const [callbackGet, loading, abort] = useGet();

  const [totalOrderPriceUsdt, setTotalOrderPriceUsdt] = useState(0);
  const [totalOrderPriceTon, setTotalOrderPriceTon] = useState(0);
  const [totalOrderPriceRub, setTotalOrderPriceRub] = useState(0);
  const [freshestUpdatedAt, setFreshestUpdatedAt] = useState(null);

  useEffect(() => {
    if (data.compositionOrders !== null) {
      setTotalOrderPriceUsdt(
        data?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostUsdt * current.count, 0)
      );
      setTotalOrderPriceTon(
        data?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostTon * current.count, 0)
      );
      setTotalOrderPriceRub(
        data?.compositionOrders?.reduce((prev, current) => prev + current.fixedCostRub * current.count, 0)
      );

      // Нахождение даты, когда наполнение ордера последний раз обновлялось
      const freshestUpdatedAt = data?.compositionOrders?.reduce((latest, current) => {
        return new Date(current.updatedAt) > new Date(latest) ? current.updatedAt : latest;
      }, new Date(0).toISOString()); // Начальное значение - древняя дата

      setFreshestUpdatedAt(freshestUpdatedAt);
    }
  }, [data]);

  useEffect(() => {
    if (expanded) {
      callbackGet(id, setData);
      return abort;
    }
  }, [expanded, id]);

  if (!data.compositionOrders || loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Text loading={true} sx={{ width: "180%" }} />
        <Text loading={true} />
      </Box>
    );
  }

  return (
    <div>
      {data.status === "open" && (
        <Text
          sx={{ color: "red", fontWeight: "bold", marginBottom: "12px" }}
          caption={`Дата последнего обновления корзины клиентом: ${getDate(freshestUpdatedAt)}`}
        />
      )}
      {data.compositionOrders.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              border: "1px solid gray",
              background: "#f0f0f0",
              borderRadius: "8px",
              padding: "8px",
              margin: "6px 0",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              marginBottom: index !== data.compositionOrders.length - 1 ? "36px" : "0px"
            }}
          >
            <Box key={index} sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
              <Text caption={item.good?.caption} checkRender={() => !!item.good?.caption} />
              <Text
                caption={`Количество: ${item.count} упаковки весом по ${item.packageWeight}`}
                checkRender={() => !!item.count}
              />
            </Box>
            <Box
              key={index + 100}
              sx={{
                border: 1,
                borderColor: "gray",
                borderRadius: "8px",
                padding: "8px",
                margin: "12px 0",
                background: "white"
              }}
            >
              <Text sx={{ fontWeight: "700" }} caption={"За одну упаковку:"} />
              <div style={{ display: "flex", justifyContent: "space-between", gap: 1, marginTop: "4px" }}>
                <Text
                  caption={`Цена в долларах: ${item.fixedCostUsdt.toFixed(2)}`}
                  checkRender={() => !!item.fixedCostUsdt}
                />
                <Text
                  caption={`Цена в рублях: ${item.fixedCostRub.toFixed(2)}`}
                  checkRender={() => !!item.fixedCostUsdt}
                />
                <Text
                  caption={`Цена в TON: ${item.fixedCostTon.toFixed(2)}`}
                  checkRender={() => !!item.fixedCostUsdt}
                />
              </div>
            </Box>
            {item.count > 1 && (
              <Box
                key={index + 100}
                sx={{
                  border: 1,
                  borderColor: "gray",
                  borderRadius: "8px",
                  padding: "8px",
                  margin: "12px 0",
                  background: "white"
                }}
              >
                <Text sx={{ fontWeight: "700" }} caption={`За все ${item.count} упаковок:`} />
                <div style={{ display: "flex", justifyContent: "space-between", gap: 1, marginTop: "4px" }}>
                  <Text
                    caption={`Цена в долларах: ${(item.fixedCostUsdt * item.count).toFixed(2)}`}
                    checkRender={() => !!item.fixedCostUsdt}
                  />
                  <Text
                    caption={`Цена в рублях: ${(item.fixedCostRub * item.count).toFixed(2)}`}
                    checkRender={() => !!item.fixedCostUsdt}
                  />
                  <Text
                    caption={`Цена в TON: ${(item.fixedCostTon * item.count).toFixed(2)}`}
                    checkRender={() => !!item.fixedCostUsdt}
                  />
                </div>
              </Box>
            )}
          </div>
        );
      })}
      {totalOrderPriceRub !== undefined && totalOrderPriceTon !== undefined && totalOrderPriceUsdt !== undefined && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "16px",
            padding: "8px",
            border: "1px solid gray",
            borderRadius: "8px",
            backgroundColor: "lightgreen",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <Text
            sx={{ marginLeft: 1, fontSize: "18px" }}
            caption={`Общая сумма ${data.status === "open" ? "корзины" : "заказа"}:
         $${totalOrderPriceUsdt.toFixed(2)} ; ${totalOrderPriceRub.toFixed(2)} ₽ ;
          ${totalOrderPriceTon.toFixed(2)} TON `}
          />
        </Box>
      )}
    </div>
  );
}, areEqualObject);

export default Default;

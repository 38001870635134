import { useCallback } from "react";
import useParamsApi from "./useParamsAPI";

const urlBase = "/api/delivery-address";

const useGetById = (props = {}) => {
  const { get, loading, response } = useParamsApi(urlBase);
  return [
    useCallback(
      (id, setData) => {
        get(`?orderId=${id}`).then((data) => {
          if (response.ok) {
            setData(data ? { ...data } : {});
          }
        });
      },
      [response, get]
    ),
    loading
  ];
};

export { useGetById };

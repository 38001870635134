export const getDateFromString = (stringDate) => {
  const fix = (value) => {
    return String(value).padStart(2, "0");
  };

  const date = new Date(stringDate);
  return `${fix(date.getDate())}.${fix(date.getMonth() + 1)}.${date.getFullYear()} ${fix(date.getHours())}:${fix(
    date.getMinutes()
  )}:${fix(date.getSeconds())}`;
};

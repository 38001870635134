import useAccessGet from "./access";
import { useAuth } from "./auth";
import { post as sendMessagePost } from "./sendMessage";
import {
  useGet as useGoodGet,
  useGetById as useGoodGetById,
  useGetById2 as useGoodGetById2,
  useDelete as useGoodDelete,
  usePost as useGoodPost,
  useUpdate as useGoodUpdate,
  useGetAll as useGoodGetAll
} from "./goods";
import {
  useGet as useArticleGet,
  useGetAll as useArticleGetAll,
  useGetById as useArticleGetById,
  useDelete as useArticleDelete,
  usePost as useArticlePost,
  useUpdate as useArticleUpdate
} from "./articles";
import { useUpdate as useDeliveryInfoUpdate } from "./cart";
import {
  useGet as useCategoryGet,
  useGetAll as useCategoryGetAll,
  useGetById as useCategoryGetById,
  useDelete as useCategoryDelete,
  usePost as useCategoryPost,
  useUpdate as useCategoryUpdate
} from "./categories";
import {
  useGet as useClientsGet,
  useGetAll as useClientsGetAll,
  useGetOneById as useClientGetById,
  useGetManyById as useClientsGetById,
  useDelete as useClientsDelete,
  usePost as useClientsPost,
  useUpdate as useClientsUpdate
} from "./clients";
import { useGetById as useDeliveryAddressGetById } from "./deliveryAddress";
import {
  useGet as useOrdersGet,
  useGetAll as useOrdersGetAll,
  useGetOneById as useOrderGetById,
  useGetManyById as useOrdersGetById,
  useDelete as useOrdersDelete,
  usePost as useOrdersPost,
  useUpdate as useOrdersUpdate
} from "./orders";
import {
  useGet as useUsersGet,
  useGetById as useUsersGetById,
  useDelete as useUsersDelete,
  usePost as useUsersPost,
  useUpdate as useUsersUpdate
} from "./users";
import {
  useGetById as useGoodCompositionGetById,
  useUpdate as useGoodCompositionUpdate,
  useDelete as useGoodCompositionDelete,
  usePost as useGoodCompositionPost
} from "./goodComposition";
import { usePost as useImagesPost, useGet as useImagesGet, useGetWithStatus as useImagesGetWithStatus } from "./images";

export {
  sendMessagePost,
  useAuth,
  useGoodGet,
  useArticleGet,
  useGoodGetById,
  useAccessGet,
  useGoodDelete,
  useArticleGetAll,
  useGoodGetById2,
  useGoodPost,
  useGoodUpdate,
  useGoodGetAll,
  useArticleGetById,
  useArticleDelete,
  useArticlePost,
  useArticleUpdate,
  useDeliveryInfoUpdate,
  useCategoryGet,
  useCategoryGetAll,
  useCategoryGetById,
  useCategoryDelete,
  useCategoryPost,
  useCategoryUpdate,
  useClientsGet,
  useClientsGetAll,
  useClientsGetById,
  useClientsDelete,
  useClientsPost,
  useClientsUpdate,
  useDeliveryAddressGetById,
  useOrdersGet,
  useOrdersGetAll,
  useOrdersGetById,
  useOrdersDelete,
  useOrdersPost,
  useOrdersUpdate,
  useOrderGetById,
  useGoodCompositionGetById,
  useGoodCompositionUpdate,
  useGoodCompositionDelete,
  useGoodCompositionPost,
  useClientGetById,
  useUsersGet,
  useUsersGetById,
  useUsersDelete,
  useUsersPost,
  useUsersUpdate,
  useImagesPost,
  useImagesGet,
  useImagesGetWithStatus
};

import { memo } from "react";
import { Box, Icon, Text } from "@components";
import { areEqualObject } from "@utils";

const Default = memo((props) => {
  const { first, orderAVGSum = 0, orderSum = 0, last, username, totalOrdersCount = 0 } = props;

  const avgSum = orderAVGSum || 0;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: 1
      }}
      name="TOP CONTAINER"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          maxWidth: "40%",
          borderRight: "1px solid #bbb",
          paddingRight: "16px"
        }}
      >
        <Icon textIcon="account_circle" sx={{ fontSize: "2rem" }} />
        <Text caption={`${first ?? ""} ${last ?? ""}`} />
        <Text
          caption={
            <div>
              {username ? (
                <a style={{ marginLeft: "8px" }} href={`https://t.me/${username}`}>
                  {`https://t.me/${username}`}
                </a>
              ) : (
                ""
              )}
            </div>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          maxWidth: "20%",
          marginLeft: "12px",
          borderRight: "1px solid #bbb",
          paddingRight: "16px"
        }}
      >
        <Icon textIcon="payments" sx={{ fontSize: "2rem" }} />
        <Text caption={`Средняя сумма чека: $${avgSum.toFixed(2)}`} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          maxWidth: "20%",
          marginLeft: "12px",
          borderRight: "1px solid #bbb",
          paddingRight: "16px"
        }}
      >
        <Icon textIcon="payments" sx={{ fontSize: "2rem" }} />
        <Text caption={`Сумма всех чеков: $${orderSum.toFixed(2)}`} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, maxWidth: "20%", marginLeft: "12px" }}>
        <Icon textIcon="shopping_cart" sx={{ fontSize: "2rem" }} />
        <Text caption={`Совершено заказов: ${totalOrdersCount}`} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );
}, areEqualObject);

export default Default;

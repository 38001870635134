import convertToPrice from './convertToPrice'
import correctRouter from './correctRouter'
import { isFunc, isFuncDef } from './isFunc'
import checkInput from './checkInput'
import testReRender, { areEqualObject, areAlwaysEqual, areEqualAlways } from './areRender'
import { getValue } from './utils'
import { compressImage } from './imageCompressor'
import { getDateFromString } from './dateConverter'

export {
  convertToPrice,
  correctRouter,
  isFunc,
  isFuncDef,
  checkInput,
  areEqualObject,
  areAlwaysEqual,
  areEqualAlways,
  testReRender,
  getValue,
  compressImage,
  getDateFromString
}
